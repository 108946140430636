<template>
  <div ref="body" id="cat" >
    <div ref="player1" id="player1" class="game-element" />
    <div ref="player2" id="player2" class="game-element" />
    <div ref="gameOver" id="game-over" >Game Over! Press F5 to restart.</div>
    <div ref="scoreElement" id="score" >Score: 0</div>
    <div id="ads">
      <div class="game-ads">
        <Adsense 
            data-ad-client="ca-pub-6699354447815429" 
            data-ad-slot="8230887469"
            data-ad-format="horizontal"
            data-full-width-responsive="true">
        </Adsense>
      </div>
    </div>
  </div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import { inject } from 'vue'

export default {
  setup() {
    const setMeta = inject('setMeta')
    setMeta({
      title: 'SoSoCEO - 사업하는 개발자',
      keywords: 'sosoceo,개발,IT,코딩,예제,사업,비즈니스,개발자,developer',
      description: '간단한 HTML 게임 예제입니다.',
      'og:title': 'SoSoCEO - 사업하는 개발자',
      'og:description': '간단한 HTML 게임 예제입니다.',
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': 'https://geek9.kr/cat',
      'canonical': 'https://geek9.kr/cat'
    })
    // useMeta( {
    //   title: 'SoSoCEO - 사업하는 개발자',
    //   link: [{rel: "canonical", href: 'https://geek9.kr/cat'}],
    //   meta: [
    //     { vmid: 'charset_cat', charset: 'utf-8' },
    //     { vmid: 'http-equiv_cat', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge,chrome=1' },
    //     { vmid: 'company_cat', name: 'company', itemprop: 'name', content: 'GEEK9' },
    //     { vmid: 'keywords_cat', name: 'keywords', content:'sosoceo,개발,IT,코딩,예제,사업,비즈니스,개발자,developer' },
    //     { vmid: 'viewport_cat', name: 'viewport', content: 'width=device-width' },
    //     { vmid: 'description_cat', name: 'description', content: '간단한 HTML 게임 예제입니다.' },
    //     { vmid: 'theme-color_cat', name: 'theme-color', content: '#ffa07a'},
    //     { vmid: 'og:site_name_cat', property: 'og:site_name', content: 'GEEK9'},
    //     { vmid: 'og:description_cat', property: 'og:description', content: '간단한 HTML 게임 예제입니다.'},
    //     { vmid: 'og:title_cat', property: 'og:title', content: 'SoSoCEO - 사업하는 개발자'},
    //     { vmid: 'og:image_cat', property: 'og:image', content: 'https://geek9.kr/img/logo_big.e0186dc8.jpg'},
    //     { vmid: 'og:url_cat', property: 'og:url', content: 'https://geek9.kr/cat'}
    //   ]
    // })
    return {
    }
  },
  components: {
  },
  mounted() {
    this.api.setPage('cat')
    console.log(this.api.getCookies('page'))
    document.addEventListener('keydown', this.handleKeyPress)
    document.addEventListener('keyup', this.handleKeyUp)
    this.init()
    this.$emit("setMenu", {page:'cat'})

    function preventScroll(event) {
      // 방향키인 경우에만 스크롤 동작을 막음
      if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
        event.preventDefault();
      }
    }

    // 스크롤 방향키 이벤트 리스너 추가
    window.addEventListener("keydown", preventScroll, false);

  },
  data(){
    return{
      keys:{},
      obstacleCount: 5,
      player1Position:{ x: 20, y: 50 },
      player2Position:{ x: 70, y: 50 },
      score:0,
      obstacles:[]
    }
  },
  methods : {
    init() {
      let body = document.getElementById('cat')
      for (let i = 0; i < this.obstacleCount; i++) {
        const obstacle = document.createElement('div');
        obstacle.className = `game-element obstacle ${this.randomShape()}`;
        body.appendChild(obstacle);
        this.obstacles.push({ element: obstacle, position: { x: Math.random() * 100, y: Math.random() * 100 } });
      }

      setInterval(() => {
        if (!this.$refs.gameOver.style.display) {
          this.movePlayers()
          this.moveObstacles()
          this.updatePositions()
          if (this.checkCollision()) {
            this.endGame();
          }
        }
      }, 1000 / 60) // 60fps로 업데이트
    },
    randomShape() {
      const shapes = ['circle', 'square', 'triangle', 'star'];
      return shapes[Math.floor(Math.random() * shapes.length)];
    },
    updatePositions() {
      this.$refs.player1.style.top = `${this.player1Position.y}%`;
      this.$refs.player1.style.left = `${this.player1Position.x}%`;

      this.$refs.player2.style.top = `${this.player2Position.y}%`;
      this.$refs.player2.style.left = `${this.player2Position.x}%`;

      this.obstacles.forEach((obstacle) => {
        obstacle.element.style.top = `${obstacle.position.y}%`;
        obstacle.element.style.left = `${obstacle.position.x}%`;
      });
    },
    movePlayers() {
      const step = 5

      // Player 1 controls
      if (this.keys['w'] && this.player1Position.y > 0) {
        this.player1Position.y -= step
      }
      if (this.keys['s'] && this.player1Position.y < 100) {
        this.player1Position.y += step
      }
      if (this.keys['a'] && this.player1Position.x > 0) {
        this.player1Position.x -= step
      }
      if (this.keys['d'] && this.player1Position.x < 100) {
        this.player1Position.x += step
      }

      // Player 2 controls
      if (this.keys['ArrowUp'] && this.player2Position.y > 0) {
        this.player2Position.y -= step
      }
      if (this.keys['ArrowDown'] && this.player2Position.y < 100) {
        this.player2Position.y += step
      }
      if (this.keys['ArrowLeft'] && this.player2Position.x > 0) {
        this.player2Position.x -= step
      }
      if (this.keys['ArrowRight'] && this.player2Position.x < 100) {
        this.player2Position.x += step
      }
    },
    moveObstacles() {
      const obstacleSpeed = 0.5 // Adjust the speed here
      this.obstacles.forEach((obstacle) => {
        // 장애물을 아래로 이동
        obstacle.position.y += obstacleSpeed;
        // 화면 아래쪽 끝에 도달하면 다시 위쪽으로 이동
        if (obstacle.position.y > 100) {
          obstacle.position.y = 0
          obstacle.position.x = Math.random() * 100 // 장애물의 가로 위치를 랜덤하게 설정
          obstacle.element.className = `game-element obstacle ${this.randomShape()}`;
          this.score += 1 // 점수 증가
          this.$refs.scoreElement.textContent = `Score: ${this.score}`
        }

        // 장애물을 왼쪽으로 이동
        obstacle.position.x += obstacleSpeed

        // 화면 왼쪽 끝에 도달하면 다시 오른쪽으로 이동
        if (obstacle.position.x > 100) {
          obstacle.position.x = 0
          obstacle.position.y = Math.random() * 100 // 장애물의 세로 위치를 랜덤하게 설정
          obstacle.element.className = `game-element obstacle ${this.randomShape()}`
          this.score += 1 // 점수 증가
          this.$refs.scoreElement.textContent = `Score: ${this.score}`
        }
      });
    },
    checkCollision() {
      const player1Rect = this.$refs.player1.getBoundingClientRect()
      const player2Rect = this.$refs.player2.getBoundingClientRect()

      return this.obstacles.some((obstacle) => {
        const obstacleRect = obstacle.element.getBoundingClientRect()
        return (
          (player1Rect.top < obstacleRect.bottom &&
          player1Rect.bottom > obstacleRect.top &&
          player1Rect.left < obstacleRect.right &&
          player1Rect.right > obstacleRect.left) ||
          (player2Rect.top < obstacleRect.bottom &&
          player2Rect.bottom > obstacleRect.top &&
          player2Rect.left < obstacleRect.right &&
          player2Rect.right > obstacleRect.left)
        )
      })
    },
    endGame() {
      this.$refs.gameOver.style.display = 'block'
      document.removeEventListener('keydown', this.handleKeyPress)
      document.removeEventListener('keyup', this.handleKeyUp)
    },
    handleKeyPress(event) {
      this.keys[event.key] = true
    },
    handleKeyUp(event) {
      this.keys[event.key] = false
    }
  }
}
</script>

<style>
#cat {
  /* background-color: skyblue; */
  margin: 0;
  overflow: hidden;
  z-index: 999999998;
  /* position:absolute; */
  /* top:0px;
  left:0px; */
  /* width:100%;
  height:100%; */
}

.game-element {
  position: absolute;
  z-index: 999999998;
}

#player1, #player2 {
  width: 50px;
  height: 50px;
  position: absolute;
  background-size: cover;
  z-index: 999999998;
}

#player1 {
  top: 50%;
  left: 20%;
  background-image: url('../../assets/cat1.png'); /* 첫 번째 고양이 이미지 경로로 변경 */
  z-index: 999999998;
}

#player2 {
  top: 50%;
  left: 70%;
  background-image: url('../../assets/cat2.png'); /* 두 번째 고양이 이미지 경로로 변경 */
  z-index: 999999998;
}

.obstacle {
  width: 30px;
  height: 30px;
  z-index: 999999998;
}

.circle {
  background-color: red;
  border-radius: 50%;
  z-index: 999999998;
}

.square {
  background-color: blue;
  z-index: 999999998;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 26px solid green;
  z-index: 999999998;
}

.star {
  position: relative;
  width: 0;
  height: 0;
  margin: 35px 0 0 35px;
  transform: rotate(45deg);
  background: yellow;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 70%, 0 100%, 0 0, 50% 30%);
  z-index: 999999998;
}

#game-over {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  font-size: 24px;
  color: black;
  z-index: 999999998;
}

#score {
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: white;
  z-index: 999999998;
}

#ads {
  z-index: 999999999;
  position:fixed;
  bottom:0px;
  left:0px;
  width:100%;
  height:150px;
  display: flex;
  justify-content: center;
}
.game-ads {
  z-index: 999999999;
  width:100%;
  height:100%;
}
</style>
